var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: 'en',
    auto_language: 'document',
    autoclear_cookies: true,
    page_scripts: true,
    force_consent: false,
    onFirstAction: function (user_preferences, cookie) {},
    onAccept: function (cookie) {},
    onChange: function (cookie, changed_preferences) {},
    languages: {
        'en': {
            consent_modal: {
                title: '',
                description: 'Our website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage',
                        description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. Our <a href="#" target="_blank">Privacy Policy</a>'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com'
                            }
                        ]
                    }
                ]
            }
        }
    }
});
/*let cookiePopupLink = document.querySelector('#cc_div button[data-cc="c-settings"]');
if (cookiePopupLink) {
    cookiePopupLink.click();
} */

