jQuery(document).ready(function ($) {
    //AOS.init();

    const header = document.querySelector('header.site-header');
    document.querySelector('.menuTrigger').addEventListener('click', function(e) {
      header.classList.toggle('is-open');
    });

    let categorySliders = [];

    $('.product-tabs li').on('click', function () {
        //if (!$(this).hasClass('is-active')) {
        let idx = $(this).closest('.categories-slider').data('idx');
        categorySliders[idx].go($(this).data('idx'));
        let requestData = {
            'action': 'getProducts',
            'category': $(this).data('cat')
        };

        let selectedSection = $(this).closest('.product-tabs');

        $('.loader').show();
        $.post(woocommerce_params.ajax_url, requestData, function (res) {
            selectedSection.find('.tab-panels .panel').html(res).fadeIn('slow');
            initSliders(document.getElementsByClassName('product-slider'), productSliderArgs, 'product');
            $('.loader').hide();
        });
        // }
    })

    $('.posts-tabs li').on('click', function () {
        //if (!$(this).hasClass('is-active')) {
        let idx = $(this).closest('.categories-slider').data('idx');
        console.log(idx);
        console.log(categorySliders);
        categorySliders[idx].go($(this).data('idx'));
        let requestData = {
            'action': 'getPosts',
            'category': $(this).data('cat')
        };
        let selectedSection = $(this).closest('.posts-tabs');

        $('.loader').show();
        $.post(woocommerce_params.ajax_url, requestData, function (res) {
            selectedSection.find('.tab-panels .panel').html(res).fadeIn('slow');
            initSliders(document.getElementsByClassName('post-slider'), postSliderArgs, 'post');
            $('.loader').hide();
        });
        // }
    })

    function initSliders(elements, args, type) {
        if (elements) {
            for (let i = 0; i < elements.length; i++) {
                let sliderInstance = new Splide(elements[i], args);

                if (type === 'category') {
                    categorySliders[i] = sliderInstance;
                } else if (type === 'product') {
                    productSliders[i] = sliderInstance;
                } else if (type === 'post') {
                    postSliders[i] = sliderInstance;
                } else if (type === 'section') {
                    sectionSliders[i] = sliderInstance;
                }
                sliderInstance.mount();
            }
        }
    }


    let productSliders = [];

    let productSliderArgs = {
        perPage: 3,
        perMove: 3,
        gap: 100,
        arrows: false,
        pagination: true,
        breakpoints: {
            800: {
                perPage: 1.2,
                perMove: 1,
                gap: 20,
            },
            1024: {
                perPage: 2,
                perMove: 2,
                arrows: false,
                pagination: true,
                gap: 40,
            },
        }
    }

    initSliders(document.getElementsByClassName('product-slider'), productSliderArgs, 'product');

    let categorySliderArgs = {
        perPage: 5,
        perMove: 1,
        gap: 0,
        arrows: false,
        pagination: false,
        breakpoints: {
            640: {
                perPage: 2,
                arrows: false
            },
        }
    };
    initSliders(document.getElementsByClassName('categories-slider'), categorySliderArgs, 'category');

    let postSliders = [];

    let postSliderArgs = {
        perPage: 3,
        perMove: 3,
        gap: 100,
        arrows: false,
        pagination: true,
        breakpoints: {
            800: {
                perPage: 1,
                perMove: 1,
                gap: 20,
            },
            1024: {
                perPage: 2,
                perMove: 2,
                arrows: false,
                pagination: true,
                gap: 40,
            },
        }
    }

    initSliders(document.getElementsByClassName('post-slider'), productSliderArgs, 'post');
    let sectionSliders = [];
    let sectionSliderArgs = {
        type: 'loop',
        perPage: 6,
        perMove: 1,
        gap: 40,
        arrows: false,
        pagination: true,
        breakpoints: {
            800: {
                perPage: 1,
                perMove: 1,
                gap: 20,
            },
            1200: {
                perPage: 2,
                perMove: 2,
                arrows: false,
                pagination: true,
                gap: 40,
            },
        }
    }

    initSliders(document.getElementsByClassName('section-slider'), sectionSliderArgs, 'section');

    let accordionElement = document.querySelector('.accordion');
    if (accordionElement) {
        $('.accordion__item .accordion__header').on('click', function () {
            $(this).toggleClass('active');
            $(this).closest('.accordion__item').find('.accordion__content').slideToggle();
        })
    }


    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('header.site-header').outerHeight();

    $(window).scroll(function () {
        let st = $(this).scrollTop();

        if (Math.abs(lastScrollTop - st) <= delta) {
            return;
        }

        if (st > lastScrollTop && st > navbarHeight) {
            $('header.site-header').removeClass('scroll-down').addClass('scroll-up');
        } else if (st + $(window).height() < $(document).height()) {
            $('header.site-header').removeClass('scroll-up').addClass('scroll-down');
        }

        lastScrollTop = st;
    });

    $(document).on('click', '.quantity button', setQuantity);

    function setQuantity() {
        let quantityEl = $(this).parent('.quantity');
        let qty = quantityEl.find('.qty');
        let val = parseFloat(qty.val());
        let max = parseFloat(qty.attr('max'));
        let min = parseFloat(qty.attr('min'));
        let step = parseFloat(qty.attr('step'));

        if ($(this).is('.add')) {
            if (max && (max <= val)) {
                qty.val(max).change();
            } else {
                qty.val(val + step).change();
            }
        } else {
            if (min && (min >= val)) {
                qty.val(min).change();
            } else if (val > 0) {
                qty.val(val - step).change();
            }
        }

        quantityEl.find('.subtract').prop('disabled', (qty.val() <= 0));
    }

    const miniCartElement = document.querySelector('.mini-cart');
    let cartToggles = document.querySelectorAll('.cart-toggle');
    cartToggles.forEach(function (cartToggle) {
        cartToggle.addEventListener('click', function (e) {
            miniCartElement.classList.add('active');
            $('body').toggleClass('layer');
        })
    })


    document.querySelector('#woo-minicart .close').addEventListener('click', function (e) {
        hideMinicart();
    })

    function hideMinicart() {
        miniCartElement.classList.remove('active');
        $('body').removeClass('layer');
    }

    $('.img-popup').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }

    });

    $('.gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        },
        callbacks: {
            elementParse: function(item) {

                if(item.el[0].className == 'video') {
                    item.type='iframe';
                } else {
                    item.type = 'image',
                        item.tLoading = 'Loading image #%curr%...',
                        item.mainClass = 'mfp-img-mobile',
                        item.image = {
                            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                            titleSrc: function (item) {
                                return item.el.attr('title');
                            }
                        }
                }
            }
        },
        /*image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr('title');
            }
        }*/
    });

    $('.mega-menu .level-2 li').on('mouseover', function() {
        $('.level-3 ul').removeClass('active');
        $('.level-3 ul[data-menu="' + $(this).data('menu') + '"]').addClass('active');
    })

    document.querySelector('body').classList.remove('preload');
})
