// Sass index file
import './sass/styles.scss';

// Load js modules
import './js/cookieconsent';
import './js/cookie-init';
import './js/main';
import './js/tabs';
//import './js/slider';

